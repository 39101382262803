import {
	takeLatest,
	put,
	call,
	select,
} from "redux-saga/effects";
import {
	fetchAllSponsors,
	createSponsor,
	updateSponsor,
	deleteSponsor,
} from "../../../http/http-calls";
import {types} from "../../types/sponsor";
import {SUBMIT_START, SUBMIT_END} from "../../types/common";
import {extractErrorMessage} from "../../../common/errorInterceptor";
import {getAllSponsors} from "../../selectors/sponsor";

function* actionWatcher() {
	yield takeLatest(types.FETCH_ALL_SPONSORS, fetchAllSponsorsSaga);
	yield takeLatest(types.CREATE_SPONSOR, createSponsorSaga);
	yield takeLatest(types.UPDATE_SPONSOR, updateSponsorSaga);
	yield takeLatest(types.DELETE_SPONSOR, deleteSponsorSaga);
}

function* createSponsorSaga({payload: {sponsor}}) {
	try {
		yield put({
			type: SUBMIT_START,
		});

		const data = yield call(createSponsor, sponsor);
		const state = yield select();
		const allSponsors = getAllSponsors(state);
		const temp = allSponsors && JSON.parse(JSON.stringify(allSponsors));
		temp.push(data);

		yield put({
			type: types.ALL_SPONSORS_RESULT,
			payload: temp
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* updateSponsorSaga({payload: {sponsor}}) {
	try {
		yield put({
			type: SUBMIT_START,
		});

		const data = yield call(updateSponsor, sponsor);
		const state = yield select();
		const allSponsors = getAllSponsors(state);
		const index = allSponsors && allSponsors.findIndex(item => item.id === data.id);
		let temp = allSponsors && JSON.parse(JSON.stringify(allSponsors));

		if (index !== -1) {
			temp.splice(index, 1, data);
		}

		yield put({
			type: types.ALL_SPONSORS_RESULT,
			payload: temp
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* deleteSponsorSaga({payload: {id}}) {
	try {
		yield call(deleteSponsor, id);
		const state = yield select();
		const allSponsors = getAllSponsors(state);
		const index = allSponsors && allSponsors.findIndex(item => item.id === id);
		let temp = allSponsors && JSON.parse(JSON.stringify(allSponsors));

		if (index !== -1) {
			temp.splice(index, 1);
		}

		yield put({
			type: types.ALL_SPONSORS_RESULT,
			payload: temp
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* fetchAllSponsorsSaga() {
	try {
		const allSponsors = yield call(fetchAllSponsors);
		yield put({
			type: types.ALL_SPONSORS_RESULT,
			payload: allSponsors,
		});
	} catch (e) {
		console.log(e);
	}
}

export default actionWatcher;
