import {produce} from "immer"
import {
	ALL_USERS_RESULT,
} from "../../types/user";

const initialState = {
	allUsers: undefined,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ALL_USERS_RESULT:
			return produce(state, draft => {
				draft.allUsers = action.payload;
			});
		default:
			return state
	}
};
