import {
	takeEvery,
	takeLatest,
	take,
	put,
	race,
	call,
	select,
} from "redux-saga/effects";
import {
	createUser,
	updateUser,
	deleteUser,
	fetchAllUsers,
} from "../../../http/http-calls";
import {
	UPDATE_USER,
	FETCH_ALL_USERS,
	ALL_USERS_RESULT,
	DELETE_USER,
	CREATE_USER,
} from "../../types/user";
import {SUBMIT_START, SUBMIT_END} from "../../types/common";
import {getAllUsers} from "../../selectors/user";
import {extractErrorMessage} from "../../../common/errorInterceptor";

function* actionWatcher() {
	yield takeLatest(FETCH_ALL_USERS, fetchAllUsersSaga);
	yield takeLatest(UPDATE_USER, updateUserSaga);
	yield takeLatest(CREATE_USER, createUserSaga);
	yield takeLatest(DELETE_USER, deleteUserSaga);
}

function* updateUserSaga({payload}) {
	try {
		const state = yield select();
		yield put({
			type: SUBMIT_START,
		});

		const user = yield call(updateUser, payload);
		const allUsers = getAllUsers(state);
		const index = allUsers && allUsers.findIndex(item => item.id === user.id);
		let temp = allUsers && JSON.parse(JSON.stringify(allUsers));

		if (index !== -1) {
			temp.splice(index, 1, user);
		}

		yield put({
			type: ALL_USERS_RESULT,
			payload: temp
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* createUserSaga({payload}) {
	try {
		const state = yield select();
		yield put({
			type: SUBMIT_START,
		});

		const user = yield call(createUser, payload);
		const allUsers = getAllUsers(state);

		let temp = [];
		if (allUsers) {
			temp = allUsers && JSON.parse(JSON.stringify(allUsers));
		}
		temp.push(user)

		yield put({
			type: ALL_USERS_RESULT,
			payload: temp,
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* fetchAllUsersSaga() {
	try {
		const data = yield call(fetchAllUsers);
		yield put({
			type: ALL_USERS_RESULT,
			payload: data,
		});
	} catch (e) {
		console.log('error', e);
	}
}

function* deleteUserSaga({payload: {id}}) {
	try {
		const data = yield call(deleteUser, id);

		const state = yield select();
		const allUsers = getAllUsers(state);
		const index = allUsers && allUsers.findIndex(item => item.id === id);
		let temp = allUsers && JSON.parse(JSON.stringify(allUsers));
		temp && temp.splice(index, 1);

		yield put({
			type: ALL_USERS_RESULT,
			payload: temp
		});
	} catch (e) {
		console.log('error', e);
	}
}

export default actionWatcher;
