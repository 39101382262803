import {
	takeLatest,
	put,
	call,
} from "redux-saga/effects";
import {
	fetchAllCategories,
} from "../../../http/http-calls";
import {
	FETCH_ALL_CATEGORIES,
	ALL_CATEGORIES_RESULT,
} from "../../types/common";

function* actionWatcher() {
	yield takeLatest(FETCH_ALL_CATEGORIES, fetchAllCategoriesSaga);
}

function* fetchAllCategoriesSaga() {
	try {
		const allCategories = yield call(fetchAllCategories);

		yield put({
			type: ALL_CATEGORIES_RESULT,
			payload: allCategories
		});
	} catch (e) {
		console.log('error', e);
	}
}

export default actionWatcher;
