import {
   makeGetRequest,
   makePostRequest,
   makePutRequest,
   makeDeleteRequest,
} from "./http-service";
import config from "../config/index";
import {endPoints} from "../config/end-points";

/**
 * auth
 * @param {object} data email and password
 */
export const login = (data) => {
   return new Promise((resolve, reject) => {
	  makePostRequest(config.baseUrl + endPoints.login, false, data)
		 .then(res => {
			resolve(res);
		 })
		 .catch(e => {
			console.log("API call error: ", e);
			reject(e);
		 });
   });
};

/**
 * register
 * @param {object} data email and password
 */
export const register = (data) => {
   return new Promise((resolve, reject) => {
	  makePostRequest(config.baseUrl + endPoints.register, false, data)
		 .then(res => {
			resolve(res);
		 })
		 .catch(e => {
			console.log("API call error: ", e);
			reject(e);
		 });
   });
};

export const createUser = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.user, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

/**
 *@description updateUser - to update the user details
 * @param {object} data information to be updated with
 */
export const updateUser = (data) => {
   return new Promise((resolve, reject) => {
	  makePutRequest(config.baseUrl + endPoints.user + "/" + data.id, true, data)
		 .then(res => {
			resolve(res);
		 })
		 .catch(e => {
			console.log("API call error: ", e);
			reject(e);
		 });
   });
};

export const deleteUser = (id) => {
	return new Promise((resolve, reject) => {
		makeDeleteRequest(config.baseUrl + endPoints.user + "/" + id, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const createSponsor = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.sponsor, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const updateSponsor = (data) => {
	return new Promise((resolve, reject) => {
		makePutRequest(config.baseUrl + endPoints.sponsor, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const deleteSponsor = (id) => {
	return new Promise((resolve, reject) => {
		makeDeleteRequest(config.baseUrl + endPoints.sponsor + "/" + id, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const fetchAllSponsors = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.fetchAllSponsors, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const fetchAllUsers = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.user, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const fetchAllMeetups = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.meetup, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const fetchAllCategories = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.fetchAllCategories, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const createScheduledGroup = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.createScheduledGroup, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const createScheduledOne = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.createScheduledOne, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const updateMeetup = (data) => {
	return new Promise((resolve, reject) => {
		makePutRequest(config.baseUrl + endPoints.meetup, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const deleteMeetup = (id) => {
	return new Promise((resolve, reject) => {
		makeDeleteRequest(config.baseUrl + endPoints.meetup + "/" + id, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

export const getCategoriesForSG = (id) => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.categoriesForSG, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
}

/**
 * @description upload image
 */
export const uploadImage = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.uploadImage, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const saveBanner = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.banner, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const loadBanner = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.banner, false)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const fetchAllGroups = () => {
	return new Promise((resolve, reject) => {
		makeGetRequest(config.baseUrl + endPoints.groupList, true)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const createGroup = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.createGroup, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const updateGroup = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.updateGroup, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};

export const deleteGroup = (data) => {
	return new Promise((resolve, reject) => {
		makePostRequest(config.baseUrl + endPoints.deleteGroup, true, data)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.log("API call error: ", e);
				reject(e);
			});
	});
};
