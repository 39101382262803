export const endPoints = {
	login: "auth/login/admin",
	register: "auth/register/admin",
	forgotPassword: "auth/forgot-password",
	user: "user",
	fetchAllSponsors: "sponsor/admin-all",
	sponsor: "sponsor",
	fetchAllCategories: "category",
	meetup: "meetup",
	categoriesForSG: "category/type-sg",
	createScheduledGroup: "meetup/admin/scheduled-group/create",
	createScheduledOne: "meetup/admin/scheduled-one/create",
	uploadImage: "file-upload/upload-image",
	banner: "banner",
	groupList: 'group/admin/group-list',
	createGroup: 'group/admin/create',
	updateGroup: 'group/admin/update',
	deleteGroup: 'group/admin/delete'
};
