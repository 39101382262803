import {
	takeEvery,
	takeLatest,
	take,
	put,
	race,
	call,
	select,
} from "redux-saga/effects";
import {
	createGroup,
	updateGroup,
	deleteGroup,
	fetchAllGroups,
} from "../../../http/http-calls";
import {
	UPDATE_GROUP,
	FETCH_ALL_GROUPS,
	ALL_GROUPS_RESULT,
	DELETE_GROUP,
	CREATE_GROUP,
} from "../../types/group";
import { SUBMIT_START, SUBMIT_END } from "../../types/common";
import { getAllGroups } from "../../selectors/group";
import { extractErrorMessage } from "../../../common/errorInterceptor";

function* actionWatcher() {
	yield takeLatest(FETCH_ALL_GROUPS, fetchAllGroupsSaga);
	yield takeLatest(UPDATE_GROUP, updateGroupSaga);
	yield takeLatest(CREATE_GROUP, createGroupSaga);
	yield takeLatest(DELETE_GROUP, deleteGroupSaga);
}

function* updateGroupSaga({payload}) {
	try {
		const state = yield select();
		yield put({
			type: SUBMIT_START,
		});

		const group = yield call(updateGroup, payload);
		const allGroups = getAllGroups(state);
		const index = allGroups && allGroups.findIndex(item => item.id === group.id);
		let temp = allGroups && JSON.parse(JSON.stringify(allGroups));

		if (index !== -1) {
			temp.splice(index, 1, group);
		}

		yield put({
			type: ALL_GROUPS_RESULT,
			payload: temp
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* createGroupSaga({payload}) {
	try {
		const state = yield select();
		yield put({
			type: SUBMIT_START,
		});

		const group = yield call(createGroup, payload);
		const allGroups = getAllGroups(state);

		let temp = [];
		if (allGroups) {
			temp = allGroups && JSON.parse(JSON.stringify(allGroups));
		}
		temp.push(group)

		yield put({
			type: ALL_GROUPS_RESULT,
			payload: temp,
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});
	} catch (e) {
		console.log('error', e);
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
	}
}

function* fetchAllGroupsSaga() {
	try {
		const data = yield call(fetchAllGroups);
		yield put({
			type: ALL_GROUPS_RESULT,
			payload: data,
		});
	} catch (e) {
		console.log('error', e);
	}
}

function* deleteGroupSaga({payload: {id}}) {
	try {
		const data = yield call(deleteGroup, id);

		const state = yield select();
		const allGroups = getAllGroups(state);
		const index = allGroups && allGroups.findIndex(item => item.id === id);
		let temp = allGroups && JSON.parse(JSON.stringify(allGroups));
		temp && temp.splice(index, 1);

		yield put({
			type: ALL_GROUPS_RESULT,
			payload: temp
		});
	} catch (e) {
		console.log('error', e);
	}
}

export default actionWatcher;
