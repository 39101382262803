import {
  takeLatest,
  put,
  call,
  select,
} from "redux-saga/effects";
import {
  loadBanner,
  saveBanner,
} from "../../../http/http-calls";
import {types} from "../../types/banner";
import { SUBMIT_END, SUBMIT_START } from '../../types/common';
import { extractErrorMessage } from '../../../common/errorInterceptor';

function* actionWatcher() {
  yield takeLatest(types.SAVE_BANNER, saveBannerSaga);
  yield takeLatest(types.LOAD_BANNER, loadBannerSaga);
}

function* saveBannerSaga({payload}) {
  try {
    yield put({
      type: SUBMIT_START,
    });
    const ret = yield call(saveBanner, payload);

    yield put({
      type: SUBMIT_END,
      payload: {
        success: true,
        errMessage: null
      }
    });
  } catch (e) {
    yield put({
      type: SUBMIT_END,
      payload: {
        success: false,
        errMessage: extractErrorMessage(e.message)
      }
    });
    console.log('error', e);
  }
}

function* loadBannerSaga() {
  try {
    const ret = yield call(loadBanner);

    yield put({
      type: types.BANNER_RESULT,
      payload: ret.banner,
    })
  } catch (e) {
    console.log('error', e);
  }
}

export default actionWatcher;
