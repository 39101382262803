import {all} from "redux-saga/effects";
import auth from "./auth";
import user from "./user";
import meetup from "./meetup";
import common from "./common";
import sponsor from "./sponsor";
import banner from "./banner";
import group from './group';

export default function* rootSaga() {
	yield all([
		auth(),
		user(),
		sponsor(),
		common(),
		meetup(),
		banner(),
		group()
	])
};
