import {produce} from "immer"
import {
	ALL_MEETUPS_RESULT, CATEGORIES_FOR_SG_RESULT,
} from '../../types/meetup';

const initialState = {
	allMeetups: undefined,
	categoriesForSG: undefined,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ALL_MEETUPS_RESULT:
			return produce(state, draft => {
				draft.allMeetups = action.payload;
			});
		case CATEGORIES_FOR_SG_RESULT:
			return produce(state, draft => {
				draft.categoriesForSG = action.payload;
			});
		default:
			return state
	}
};
