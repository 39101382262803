import { produce } from "immer";
import {types} from "../../types/banner";

const initialState = {
  banner: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.BANNER_RESULT:
      return produce(state, draft => {
        draft.banner = action.payload;
      });
    default:
      return state
  }
};
