import {produce} from "immer"
import {
	SUBMIT_START,
	SUBMIT_END,
	ALL_CATEGORIES_RESULT,
} from "../../types/common";

const initialState = {
	success: false,
	submitting: false,
	errMessage: null,
	allCategories: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ALL_CATEGORIES_RESULT:
			return produce(state, draft => {
				draft.allCategories = action.payload;
			});
		case SUBMIT_START:
			return produce(state, draft => {
				draft.submitting = true;
			});
		case SUBMIT_END:
			return produce(state, draft => {
				draft.submitting = false;
				draft.success = action.payload.success;
				draft.errMessage = action.payload.errMessage;
			});
		default:
			return state
	}
};
