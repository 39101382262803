import { produce } from "immer"
import {
	LOGIN_RESULT, LOGOUT,
} from "../../types/auth";

const initialState = {
   token: localStorage.getItem("token"),
   submitting: false,
   success: false,
   errMessage: null
};

export default (state = initialState, action) => {
   switch (action.type) {
	  case LOGIN_RESULT:
		 return produce(state, draft => {
			draft.token = action.payload.token;
		 });
	  case LOGOUT:
		 return produce(state, draft => {
		    draft.token = null;
		 });
	  default:
		 return state
   }
};
