// import external modules
import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import meetup from "./meetup";
import common from "./common";
import sponsor from "./sponsor";
import banner from "./banner";
import group from './group';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   toastr: toastrReducer, // <- Mounted at toastr.
   auth: auth,
   user: user,
   sponsor: sponsor,
   common: common,
   meetup: meetup,
   banner: banner,
   group: group
});

export default rootReducer;
