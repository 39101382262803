import {
	takeLatest,
	put,
	call,
} from "redux-saga/effects";
import {
	login,
	register
} from "../../../http/http-calls";
import {
	REGISTER,
	LOGIN, LOGIN_RESULT,
} from "../../types/auth";
import {
	SUBMIT_START,
	SUBMIT_END
} from "../../types/common";
import {extractErrorMessage} from "../../../common/errorInterceptor";

function* actionWatcher() {
	yield takeLatest(LOGIN, loginSaga);
	yield takeLatest(REGISTER, registerSaga);
}

function* registerSaga({payload: {email, password}}) {
	try {
		yield put({
			type: SUBMIT_START,
		});

		yield call(register, {
			userName: email,
			password
		});

		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
				errMessage: null
			}
		});
	} catch (e) {
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
		console.log('error', e.message);
	}
}

function* loginSaga({payload: {email, password}}) {
	try {
		yield put({
			type: SUBMIT_START
		});

		const res = yield call(login, {
			userName: email,
			password
		});

		yield put({
			type: LOGIN_RESULT,
			payload: {
				token: res.accessToken,
			}
		});
		yield put({
			type: SUBMIT_END,
			payload: {
				success: true,
			}
		});

		localStorage.setItem("token", res.accessToken);
	} catch (e) {
		yield put({
			type: SUBMIT_END,
			payload: {
				success: false,
				errMessage: extractErrorMessage(e.message)
			}
		});
		console.log('error', e);
	}
}

export default actionWatcher;
