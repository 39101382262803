import {
  takeLatest,
  put,
  call,
  select,
} from 'redux-saga/effects';
import {
  fetchAllMeetups,
  updateMeetup,
  deleteMeetup,
  getCategoriesForSG,
  createScheduledGroup,
  createScheduledOne,
} from '../../../http/http-calls';
import {
  FETCH_ALL_MEETUPS, ALL_MEETUPS_RESULT,
  UPDATE_MEETUP,
  DELETE_MEETUP,
  FETCH_CATEGORIES_FOR_SG,
  CATEGORIES_FOR_SG_RESULT,
  CREATE_SCHEDULED_GROUP,
  CREATE_SCHEDULED_ONE,
} from '../../types/meetup';
import { getAllMeetups } from '../../selectors/meetup';

function* actionWatcher() {
  yield takeLatest(FETCH_ALL_MEETUPS, fetchAllMeetupsSaga);
  yield takeLatest(UPDATE_MEETUP, updateMeetupSaga);
  yield takeLatest(DELETE_MEETUP, deleteMeetupSaga);
  yield takeLatest(FETCH_CATEGORIES_FOR_SG, fetchCategoriesForSGSaga);
  yield takeLatest(CREATE_SCHEDULED_GROUP, createScheduledGroupSaga);
  yield takeLatest(CREATE_SCHEDULED_ONE, createScheduledOneSaga);
}

function* fetchAllMeetupsSaga() {
  try {
    const data = yield call(fetchAllMeetups);
    yield put({
      type: ALL_MEETUPS_RESULT,
      payload: data,
    });
  } catch (e) {
    console.log('error', e);
  }
}

function* updateMeetupSaga({ payload: { meetup } }) {
  try {
    const data = yield call(updateMeetup, meetup);
    const state = yield select();
    const allMeetups = getAllMeetups(state);
    const temp = allMeetups && JSON.parse(JSON.stringify(allMeetups));
    const index = temp.findIndex(item => item.id === data.id);

    if (index !== -1) {
      temp.splice(index, 1, data);
    }

    yield put({
      type: ALL_MEETUPS_RESULT,
      payload: temp,
    });
  } catch (e) {
    console.log('error', e);
  }
}

function* deleteMeetupSaga({ payload: { id } }) {
  try {
    yield call(deleteMeetup, id);
    const state = yield select();
    const allMeetups = getAllMeetups(state);
    const temp = allMeetups && JSON.parse(JSON.stringify(allMeetups));
    const index = temp.findIndex(item => item.id === id);

    if (index !== -1) {
      temp.splice(index, 1);
    }

    yield put({
      type: ALL_MEETUPS_RESULT,
      payload: temp,
    });
  } catch (e) {
    console.log('error', e);
  }
}

function* fetchCategoriesForSGSaga() {
  try {
    const categoriesForSG = yield call(getCategoriesForSG);

    yield put({
      type: CATEGORIES_FOR_SG_RESULT,
      payload: categoriesForSG,
    });
  } catch (e) {
    console.log('error', e);
  }
}

function* createScheduledGroupSaga({ payload: { meetup } }) {
  try {
    const ret = yield call(createScheduledGroup, meetup);
    const state = yield select();
    const allMeetups = getAllMeetups(state);
    let temp = allMeetups && JSON.parse(JSON.stringify(allMeetups));
    temp.push(ret);

    yield put({
      type: ALL_MEETUPS_RESULT,
      payload: temp,
    });
  } catch (e) {
    console.log('error', e);
  }
}

function* createScheduledOneSaga({ payload: { meetup } }) {
  try {
    const ret = yield call(createScheduledOne, meetup);
    const state = yield select();
    const allMeetups = getAllMeetups(state);
    let temp = allMeetups && JSON.parse(JSON.stringify(allMeetups));
    temp.push(ret);

    yield put({
      type: ALL_MEETUPS_RESULT,
      payload: temp,
    });
  } catch (e) {
    console.log('error', e);
  }
}

export default actionWatcher;
