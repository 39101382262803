import {produce} from "immer"
import {
	ALL_GROUPS_RESULT,
} from "../../types/group";

const initialState = {
	allGroups: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ALL_GROUPS_RESULT:
			return produce(state, draft => {
				draft.allGroups = action.payload;
			});
		default:
			return state
	}
};
